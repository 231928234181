import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"
// import StoryShape from "../images/svgs/ourStoryShape.svg"
import Gallery from "../components/gallery"
import Map from "../components/googleMap"
import Headline from "../components/headline"
import MenuItem from "../components/menu-item"
import HeroBoxes from "../components/heroBoxes"

export default function Home({ data }) {
  //Wordpress data
  const array = data.allWpPage.edges[0].node.homePage || []
  // hero
  const heroHeaderFirst = array.hero.headerFirst || ""
  const heroTxt = array.hero.text || ""
  const heroBtnTxt = array.hero.btnTxt || ""
  const heroBtnLink = array.hero.btnLink || ""
  const heroImage = array.hero.bgImg.sourceUrl || ""

  //Our Story
  const ourStoryHeader = array.ourStory.header || ""
  const ourStoryTxt = array.ourStory.text || ""
  const ourStoryBtnTxt = array.ourStory.btnText || ""
  const ourStoryBtnLink = array.ourStory.btnLink || ""
  const ourStoryImage = array.ourStory.image
    ? array.ourStory.image.sourceUrl
    : ""
  const ourStoryAltText = array.ourStory.image
    ? array.ourStory.image.altText
    : ""

  //Menu
  const menuHeader = array.menu.header || ""
  const menuBtnTxt = array.menu.btnTxt || ""
  const menuBtnLink = array.menu.btnLink || ""

  //menu items
  const menuArray = data.allWpProduct.edges || []

  //Gallery
  const galleryHeader = array.galleryHeader || ""

  return (
    <Layout>
      <SEO title="Home" />

      <section className="hero" style={{ background: `url(${heroImage})` }}>
        {/* <div className="layer"></div> */}
        <div className="container">
          <div className="hero__box">
            <h1>{heroHeaderFirst}</h1>
            <p>{heroTxt}</p>
            <Link to={heroBtnLink} className="btn">
              {heroBtnTxt}
            </Link>
          </div>
        </div>
      </section>
      <HeroBoxes />

      <section className="ourStory mt-40 md:mt-72">
        <div className="container">
          <Headline title={ourStoryHeader} />
          <div className="flex flex-wrap">
            <div className="story-images relative :mt-8 lg:mt-0 w-full lg:w-1/2">
              <img
                className=" pr-16 mt-8 lg:-mt-8 z-1 relative pb-8 "
                src={ourStoryImage}
                alt={`${ourStoryAltText}`}
              />
              {/* <StoryShape className="absolute bottom-0 right-1/2 w-full h-auto z-0" /> */}
            </div>
            <div className="story-cont lg:mt-16 w-full lg:w-1/2">
              <p>{ourStoryTxt}</p>
              <Link to={ourStoryBtnLink} className="btn">
                {ourStoryBtnTxt}
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="menu mt-20 md:mt-44"> */}
      <section className="menu mt-20">
        <div className="container">
          <Headline title={menuHeader} />
        </div>
        <div className="blue-block pt-16 pb-6 md:pt-28">
          <div className="container text-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-10">
              {menuArray.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    title={item.node.products.itemName}
                    price={`AUD ${item.node.products.price.toFixed(2)}`}
                    text={item.node.products.description}
                    img_url={item.node.products.itemImage.sourceUrl}
                    img_alt={item.node.products.itemImage.altText}
                  />
                )
              })}
            </div>
            <Link
              to={menuBtnLink}
              className="btn btn-secondary mt-14 mb-6 md:mb-20"
            >
              {menuBtnTxt}
            </Link>
          </div>
        </div>
      </section>
      {/* <section className="gallery overflow-hidden mt-20 md:mt-44"> */}
      <section className="gallery overflow-hidden mt-20">
        <div className="container gallery-cont overflow-visble mx-auto">
          <Headline title={galleryHeader} />
          <Gallery />
        </div>
      </section>
      {/* <section className="location mt-20 sm:mt-48"> */}
      <section className="location mt-20">
        <Headline title="Our Locations" />
        <Map />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query HomeQuery {
    allWpPage(filter: { id: { eq: "cG9zdDoyNQ==" } }) {
      edges {
        node {
          homePage {
            hero {
              fieldGroupName
              headerFirst
              text
              btnTxt
              btnLink
              bgImg {
                sourceUrl
              }
            }
            ourStory {
              fieldGroupName
              text
              header
              btnText
              btnLink
              image {
                altText
                sourceUrl
              }
            }
            menu {
              fieldGroupName
              header
              btnTxt
              btnLink
            }
            galleryHeader
          }
        }
      }
    }
    allWpProduct(limit: 8) {
      edges {
        node {
          products {
            fieldGroupName
            description
            itemName
            price
            itemImage {
              altText
              sourceUrl
            }
          }
        }
      }
    }
  }
`
